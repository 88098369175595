import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialPostObj } from "../../formatSocialPostObj";
import { useTwitter } from "./useTwitter";
import { CalendarActiveSocialNetworkContext } from "contexts/CalendarActiveSocialNetworkContext";
import { useFacebook } from "./useFacebook";
import { useMedium } from "./useMedium";
import { usePinterest } from "./usePinterest";
import { useTiktok } from "./useTiktok";
import { useLinkedin } from "./useLinkedin";
import { useInstagram } from "./useInstagram";
import { socialNetworks } from "views/calendar/SocialNetworkSidePanel";

export const useSocialNetworks = () => {

    const { getTwitterUserPosts, getTwitterUserConversations, getTwitterConversationMessages, sendTwitterMessage } = useTwitter();
    const { getFacebookPosts, getFacebookConversations, getFacebookConversationMessages, sendFacebookMessage, getFacebookComments, replyToFacebookComment, getFacebookPostsInsights, deleteFacebookMessage } = useFacebook();
    const { getInstagramPosts, getInstagramConversations, sendInstagramMessage, getInstagramConversationMessages, getInstagramComments, replyToInstagramComment, getInstagramPostsInsights, deleteInstagramMessage } = useInstagram();
    const { getMediumPosts } = useMedium();
    const { getPinterestPosts, getPinterestPostsInsights } = usePinterest();
    const { getTiktokPosts, getTiktokPostsInsights } = useTiktok();
    const { getLinkedinPosts } = useLinkedin();

    const postsFunctionsMap = {
        // "twitter": getTwitterUserPosts,
        "facebook": getFacebookPosts,
        "medium": getMediumPosts,
        "pinterest": getPinterestPosts,
        "tiktok": getTiktokPosts,
        "linkedin": getLinkedinPosts,
        "instagram": getInstagramPosts,
    }
    const conversationsFunctionsMap = {
        // "twitter": getTwitterUserConversations,
        "facebook": getFacebookConversations,
        "instagram": getInstagramConversations
    }
    const messagesFunctionsMap = {
        "facebook": getFacebookConversationMessages,
        "instagram": getInstagramConversationMessages
    }
    const sendMessagesFunctionsMap = {
        "facebook": sendFacebookMessage,
        "instagram": sendInstagramMessage,
    }
    const deleteMessagesFunctionsMap = {
        "facebook": deleteFacebookMessage,
        "instagram": deleteInstagramMessage,
    }
    const commentsFunctionsMap = {
        "instagram": getInstagramComments,
        "facebook": getFacebookComments,
    }
    const replyToCommentFunctionsMap = {
        "instagram": replyToInstagramComment,
        "facebook": replyToFacebookComment
    }
    const postAnalyticsFunctionsMap = {
        "instagram": getInstagramPostsInsights,
        "facebook": getFacebookPostsInsights,
        "tiktok": getTiktokPostsInsights,
        "pinterest": getPinterestPostsInsights,
    }
    
    const getPosts = async (
        per_page = 100,
        start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000),
        end_date = new Date(),
        override_social_networks = undefined
    ) => {
        let posts = [];
        const socialNetworksToFetch = override_social_networks ? override_social_networks : socialNetworks;
        for (var i = 0; i < socialNetworksToFetch?.length; i++) {
            const network = socialNetworksToFetch[i];
            let _posts = [];
            if (postsFunctionsMap?.[network]) {
                _posts = await postsFunctionsMap?.[network](per_page, start_date, end_date);
            }
            posts = [...posts, ...(_posts ? _posts : [])];
        }
        return posts;
    }

    const getPostsInsights = async (
        start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000),
        end_date = new Date(),
        per_page = 100,
        networks = []
    ) => {
        let posts = [];
        for (var i = 0; i < networks?.length; i++) {
            const network = networks[i];
            let _posts = [];
            if (postAnalyticsFunctionsMap?.[network]) {
                _posts = await postAnalyticsFunctionsMap?.[network](per_page, start_date, end_date);
            }
            posts = [...posts, ...(_posts ? _posts : [])];
        }
        return posts;
    }

    const getConversations = async (filters = {}) => {
        
        let conversations = [];

        for (var i = 0; i < socialNetworks?.length; i++) {
            const network = socialNetworks[i];
            let _conversations = [];
            if (conversationsFunctionsMap?.[network]) {
                _conversations = await conversationsFunctionsMap?.[network](filters);
            }
            conversations = [...conversations, ...(_conversations ? _conversations : [])];
        }

        return conversations;
    }

    const getConversationMessages = async (
        network,
        conversation_id,
    ) => {
        let messages = [];
        if (messagesFunctionsMap?.[network]) {
            messages = await messagesFunctionsMap?.[network](conversation_id);
        }
        return messages;
    }

    const sendConversationMessage = async (
        conversation,
        message,
    ) => {
        const network = conversation?.network;
        let response;
        if (sendMessagesFunctionsMap?.[network]) {
            response = await sendMessagesFunctionsMap?.[network](conversation, message);
        }
        return response;
    }

    const deleteConversationMessage = async (
        conversation,
        message_id,
    ) => {
        const network = conversation?.network;
        let response;
        if (deleteMessagesFunctionsMap?.[network]) {
            response = await deleteMessagesFunctionsMap?.[network](message_id);
        }
        return response;
    }

    const getComments = async (
        network,
        id,
    ) => {
        let messages = [];
        if (commentsFunctionsMap?.[network]) {
            messages = await commentsFunctionsMap?.[network](id);
        }
        return messages;
    }

    const replyToComment = async (
        comment,
        message,
    ) => {
        const network = comment?.network;
        let response;
        if (replyToCommentFunctionsMap?.[network]) {
            response = await replyToCommentFunctionsMap?.[network](comment, message);
        }
        return response;
    }

    return { 
        getPosts, 
        getConversations, 
        getConversationMessages, 
        sendConversationMessage,
        deleteConversationMessage,
        getComments,
        replyToComment,
        getPostsInsights
    }
}

